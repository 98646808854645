.access_list-name {
  min-width: 300px;
  max-width: 350px;
  a:hover {
    text-decoration: none;
    cursor: default;
  }
}

.access_list-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.access_list-short {
  min-width: 120px;
  max-width: 200px;
}

.access_list-type {
  min-width: 120px;
  margin-right: 10px;
}

.access_list-buttons {
  min-width: 120px;
  margin-right: 10px;
}

.access_list-container {
  overflow: auto;
  height: 100%;
  max-height: 600px;
  width: auto;
}
.access_list-container ul {
  margin-bottom: 5px;
}

.access_list-container li > div {
  height: 40px;
}

.ul_access_list {
  list-style-type: none;
}
