.time-selector-box {
  text-align: center;
  border-radius: 5px;
  text-align: center;
  padding: 4px 5px;
  margin: 0px 20px;
  box-sizing: border-box;
}

.focus-realtime_date_label {
  position: absolute;
  top: -30px;
  border-radius: 5px;
  color: white;
  transform: translateX(-50%);
  padding: 3px;
  background-color: rgba(40, 42, 44, 0.85);
  white-space: nowrap;
  &::after {
    content: " ";
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: -5px;
    border-top: 5px solid rgba(40, 42, 44, 0.85);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: none;
  }
}

.speed-selector {
  display: flex;
  padding: 3px 5px;
  flex-direction: column;
  justify-content: center;
  gap: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & input {
    position: absolute;
    top: 0;
    left: 0;
  }

  &_box {
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
  }

  .speed-label {
    padding: 5px;
    text-align: center;
  }
}

.intermittent {
  animation: blinker 1.5s linear infinite;
}

.intermittent-slow {
  animation: blinker 4s linear infinite;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
