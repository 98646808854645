.focusgrafos-graph_selector {
    display: flex;
    height: 90%;
    flex-direction: column;
    justify-content:space-between;
    .focusgrafos-graph_graphs{
        flex: 1;
        overflow: auto;
    }
    .focusgrafos-graph_controls{
        height: 30px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 30px;
    }
}

.tabHolder.grafosTabs{
    margin: 0;
}

.inputCells{
    margin-left: 10px;
}

th{
    height: 60px;
}