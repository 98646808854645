.bp4-datepicker{
  background:#fff;
  border-radius:2px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  padding:5px;
  position:relative;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.bp4-datepicker .DayPicker{
  display:inline-block;
  min-width:210px;
  position:relative;
  vertical-align:top;
}
.bp4-datepicker .DayPicker:focus{
  outline:none;
}
.bp4-datepicker .bp4-datepicker-day-wrapper{
  border-radius:2px;
  padding:7px;
}
.bp4-datepicker .DayPicker-Month{
  border-collapse:collapse;
  border-spacing:0;
  display:inline-table;
  margin:0 5px 5px;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.bp4-datepicker .DayPicker-Month + .bp4-datepicker .DayPicker-Month{
  margin-left:10px;
}
.bp4-datepicker .DayPicker-Caption{
  display:table-caption;
}
.bp4-datepicker .DayPicker-Weekdays{
  display:table-header-group;
}
.bp4-datepicker .DayPicker-WeekdaysRow{
  display:table-row;
}
.bp4-datepicker .DayPicker-Weekday{
  display:table-cell;
  height:30px;
  line-height:1;
  text-align:center;
  vertical-align:middle;
  width:30px;
  font-weight:600;
  padding-top:5px;
}
.bp4-datepicker .DayPicker-Weekday abbr[title]{
  text-decoration:none;
}
.bp4-datepicker .DayPicker-Body{
  display:table-row-group;
}
.bp4-datepicker .DayPicker-Week{
  display:table-row;
}
.bp4-datepicker .DayPicker-WeekNumber{
  display:table-cell;
  height:30px;
  line-height:1;
  text-align:center;
  vertical-align:middle;
  width:30px;
  color:rgba(95, 107, 124, 0.6);
  font-size:14px;
}
.bp4-datepicker .DayPicker-Day{
  display:table-cell;
  height:30px;
  line-height:1;
  text-align:center;
  vertical-align:middle;
  width:30px;
  border-radius:2px;
  cursor:pointer;
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--outside{
  color:rgba(95, 107, 124, 0.6);
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--isToday .bp4-datepicker-day-wrapper{
  border:1px solid rgba(17, 20, 24, 0.15);
}
.bp4-datepicker .DayPicker-Day:hover, .bp4-datepicker .DayPicker-Day:focus{
  background:rgba(143, 153, 168, 0.15);
  color:#1c2127;
}
.bp4-datepicker .DayPicker-Day:active{
  background:rgba(143, 153, 168, 0.3);
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--selected{
  background-color:#2d72d2;
  border-radius:2px;
  color:#fff;
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--selected:hover{
  background-color:#215db0;
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--selected:active{
  background-color:#184a90;
}
.bp4-datepicker .DayPicker-Day.DayPicker-Day--disabled{
  background:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}

.bp4-datepicker-navbar{
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  height:30px;
  left:0;
  position:absolute;
  right:0;
  top:0;
}
.bp4-datepicker-navbar > .DayPicker-NavButton--prev{
  margin-right:auto;
}
.bp4-datepicker-navbar > .DayPicker-NavButton--next{
  margin-left:auto;
}

.bp4-datepicker-caption{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
      -ms-flex-direction:row;
          flex-direction:row;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  margin:0 25px 5px;
}
.bp4-datepicker-caption > *{
  -webkit-box-flex:0;
      -ms-flex-positive:0;
          flex-grow:0;
  -ms-flex-negative:0;
      flex-shrink:0;
}
.bp4-datepicker-caption > :first-child{
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;
  -ms-flex-negative:1;
      flex-shrink:1;
}
.bp4-datepicker-caption select{
  font-weight:600;
  padding-left:5px;
  padding-right:16px;
}
.bp4-datepicker-caption select + .bp4-icon{
  right:2px;
}
.bp4-datepicker-caption + .bp4-divider{
  margin:0;
}

.bp4-datepicker-month-select{
  -ms-flex-negative:1;
      flex-shrink:1;
}

.bp4-datepicker-year-select{
  -ms-flex-negative:1;
      flex-shrink:1;
  min-width:60px;
}

.bp4-datepicker-caption-measure{
  font-weight:600;
  padding-left:5px;
}

.bp4-datepicker-footer{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
}

.bp4-dark .bp4-datepicker{
  background:#2f343c;
}
.bp4-dark .bp4-datepicker .DayPicker-WeekNumber{
  color:rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--outside{
  color:rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--isToday .bp4-datepicker-day-wrapper{
  border:1px solid rgba(255, 255, 255, 0.2);
}
.bp4-dark .bp4-datepicker .DayPicker-Day:hover, .bp4-dark .bp4-datepicker .DayPicker-Day:focus{
  background:rgba(143, 153, 168, 0.15);
  color:#fff;
}
.bp4-dark .bp4-datepicker .DayPicker-Day:active{
  background:rgba(143, 153, 168, 0.3);
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--selected{
  background-color:#2d72d2;
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--selected:hover{
  background-color:#215db0;
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--selected:active{
  background-color:#184a90;
}
.bp4-dark .bp4-datepicker .DayPicker-Day.DayPicker-Day--disabled{
  background:none;
  color:rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-datepicker .bp4-datepicker-footer{
  border-top-color:rgba(17, 20, 24, 0.4);
}

.bp4-datepicker-timepicker-wrapper{
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
}
.bp4-daterangepicker{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  white-space:nowrap;
}
.bp4-daterangepicker .DayPicker-NavButton--interactionDisabled{
  display:none;
}
.bp4-daterangepicker .bp4-daterangepicker-timepickers{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-pack:distribute;
      justify-content:space-around;
}
.bp4-daterangepicker.bp4-daterangepicker-contiguous .DayPicker{
  min-width:220px;
}
.bp4-daterangepicker.bp4-daterangepicker-single-month .DayPicker{
  min-width:210px;
}
.bp4-daterangepicker .DayPicker-Day{
}
.bp4-daterangepicker .DayPicker-Day--outside{
  visibility:hidden;
}
.bp4-daterangepicker .DayPicker-Day--hovered-range{
  border-radius:0;
  color:#215db0;
}
.bp4-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end){
  background-color:rgba(45, 114, 210, 0.1);
}
.bp4-daterangepicker .DayPicker-Day--selected-range{
  background-color:rgba(45, 114, 210, 0.1);
  border-radius:0;
  color:#215db0;
}
.bp4-daterangepicker .DayPicker-Day--selected-range:hover{
  background-color:rgba(45, 114, 210, 0.2);
  color:#215db0;
}
.bp4-daterangepicker .DayPicker-Day--selected-range-start:not(.DayPicker-Day--selected-range-end):not(.DayPicker-Day--hovered-range-end){
  border-bottom-right-radius:0;
  border-top-right-radius:0;
}
.bp4-daterangepicker .DayPicker-Day--selected-range-end:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--hovered-range-start){
  border-bottom-left-radius:0;
  border-top-left-radius:0;
}
.bp4-daterangepicker .DayPicker-Day--hovered-range-start:not(.DayPicker-Day--hovered-range-end){
  border-bottom-right-radius:0;
  border-top-right-radius:0;
}
.bp4-daterangepicker .DayPicker-Day--hovered-range-end:not(.DayPicker-Day--hovered-range-start){
  border-bottom-left-radius:0;
  border-top-left-radius:0;
}
.bp4-dark .bp4-daterangepicker .DayPicker-Day--hovered-range{
  color:#f6f7f9;
}
.bp4-dark .bp4-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end){
  background-color:rgba(45, 114, 210, 0.2);
}
.bp4-dark .bp4-daterangepicker .DayPicker-Day--selected-range{
  background-color:rgba(45, 114, 210, 0.2);
  color:#f6f7f9;
}
.bp4-dark .bp4-daterangepicker .DayPicker-Day--selected-range:hover{
  background-color:rgba(45, 114, 210, 0.4);
}

.bp4-menu.bp4-daterangepicker-shortcuts{
  min-width:120px;
  padding:0;
}
.bp4-timepicker{
  white-space:nowrap;
}
.bp4-timepicker .bp4-timepicker-arrow-row{
  padding:0 1px;
}
.bp4-timepicker .bp4-timepicker-arrow-button{
  color:#5f6b7c;
  display:inline-block;
  padding:4px 0;
  text-align:center;
  width:33px;
}
.bp4-timepicker .bp4-timepicker-arrow-button:hover{
  color:#1c2127;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-arrow-button{
  color:#abb3bf;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-arrow-button:hover{
  color:#f6f7f9;
}
.bp4-timepicker .bp4-timepicker-arrow-button + .bp4-timepicker-arrow-button{
  margin-left:11px;
}
.bp4-timepicker .bp4-timepicker-arrow-button:hover{
  cursor:pointer;
}
.bp4-timepicker .bp4-timepicker-input-row{
  background:#fff;
  border-radius:2px;
  -webkit-box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
          box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
  display:inline-block;
  height:30px;
  line-height:28px;
  padding:0 1px;
  vertical-align:middle;
}
.bp4-timepicker .bp4-timepicker-divider-text{
  color:#5f6b7c;
  display:inline-block;
  font-size:16px;
  text-align:center;
  width:11px;
}
.bp4-timepicker .bp4-timepicker-input{
  background:transparent;
  border:0;
  border-radius:2px;
  -webkit-box-shadow:0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0);
          box-shadow:0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0);
  color:#1c2127;
  height:28px;
  outline:0;
  padding:0;
  text-align:center;
  -webkit-transition:-webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition:-webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition:box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition:box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  width:33px;
}
.bp4-timepicker .bp4-timepicker-input:focus{
  -webkit-box-shadow:inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3);
          box-shadow:inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-primary{
  -webkit-box-shadow:0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px #2d72d2, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
          box-shadow:0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px #2d72d2, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-primary:focus{
  -webkit-box-shadow:inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
          box-shadow:inset 0 0 0 1px #2d72d2, 0 0 0 2px rgba(45, 114, 210, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-primary[readonly]{
  -webkit-box-shadow:inset 0 0 0 1px #2d72d2;
          box-shadow:inset 0 0 0 1px #2d72d2;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-primary:disabled, .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary.bp4-disabled{
  -webkit-box-shadow:none;
          box-shadow:none;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary{
  -webkit-box-shadow:0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), inset 0 0 0 1px #4c90f0, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
          box-shadow:0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), 0 0 0 0 rgba(76, 144, 240, 0), inset 0 0 0 1px #4c90f0, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary:focus{
  -webkit-box-shadow:inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px rgba(76, 144, 240, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
          box-shadow:inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px rgba(76, 144, 240, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary[readonly]{
  -webkit-box-shadow:inset 0 0 0 1px #4c90f0;
          box-shadow:inset 0 0 0 1px #4c90f0;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary:disabled, .bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-primary.bp4-disabled{
  -webkit-box-shadow:none;
          box-shadow:none;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-success{
  -webkit-box-shadow:0 0 0 0 rgba(35, 133, 81, 0), 0 0 0 0 rgba(35, 133, 81, 0), inset 0 0 0 1px #238551, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
          box-shadow:0 0 0 0 rgba(35, 133, 81, 0), 0 0 0 0 rgba(35, 133, 81, 0), inset 0 0 0 1px #238551, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-success:focus{
  -webkit-box-shadow:inset 0 0 0 1px #238551, 0 0 0 2px rgba(35, 133, 81, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
          box-shadow:inset 0 0 0 1px #238551, 0 0 0 2px rgba(35, 133, 81, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-success[readonly]{
  -webkit-box-shadow:inset 0 0 0 1px #238551;
          box-shadow:inset 0 0 0 1px #238551;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-success:disabled, .bp4-timepicker .bp4-timepicker-input.bp4-intent-success.bp4-disabled{
  -webkit-box-shadow:none;
          box-shadow:none;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-success{
  -webkit-box-shadow:0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), inset 0 0 0 1px #32a467, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
          box-shadow:0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), 0 0 0 0 rgba(50, 164, 103, 0), inset 0 0 0 1px #32a467, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-success:focus{
  -webkit-box-shadow:inset 0 0 0 1px #32a467, inset 0 0 0 1px #32a467, 0 0 0 2px rgba(50, 164, 103, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
          box-shadow:inset 0 0 0 1px #32a467, inset 0 0 0 1px #32a467, 0 0 0 2px rgba(50, 164, 103, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-success[readonly]{
  -webkit-box-shadow:inset 0 0 0 1px #32a467;
          box-shadow:inset 0 0 0 1px #32a467;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-success:disabled, .bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-success.bp4-disabled{
  -webkit-box-shadow:none;
          box-shadow:none;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-warning{
  -webkit-box-shadow:0 0 0 0 rgba(200, 118, 25, 0), 0 0 0 0 rgba(200, 118, 25, 0), inset 0 0 0 1px #c87619, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
          box-shadow:0 0 0 0 rgba(200, 118, 25, 0), 0 0 0 0 rgba(200, 118, 25, 0), inset 0 0 0 1px #c87619, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-warning:focus{
  -webkit-box-shadow:inset 0 0 0 1px #c87619, 0 0 0 2px rgba(200, 118, 25, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
          box-shadow:inset 0 0 0 1px #c87619, 0 0 0 2px rgba(200, 118, 25, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-warning[readonly]{
  -webkit-box-shadow:inset 0 0 0 1px #c87619;
          box-shadow:inset 0 0 0 1px #c87619;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-warning:disabled, .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning.bp4-disabled{
  -webkit-box-shadow:none;
          box-shadow:none;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning{
  -webkit-box-shadow:0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
          box-shadow:0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), 0 0 0 0 rgba(236, 154, 60, 0), inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning:focus{
  -webkit-box-shadow:inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px #ec9a3c, 0 0 0 2px rgba(236, 154, 60, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
          box-shadow:inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px #ec9a3c, 0 0 0 2px rgba(236, 154, 60, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning[readonly]{
  -webkit-box-shadow:inset 0 0 0 1px #ec9a3c;
          box-shadow:inset 0 0 0 1px #ec9a3c;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning:disabled, .bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-warning.bp4-disabled{
  -webkit-box-shadow:none;
          box-shadow:none;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-danger{
  -webkit-box-shadow:0 0 0 0 rgba(205, 66, 70, 0), 0 0 0 0 rgba(205, 66, 70, 0), inset 0 0 0 1px #cd4246, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
          box-shadow:0 0 0 0 rgba(205, 66, 70, 0), 0 0 0 0 rgba(205, 66, 70, 0), inset 0 0 0 1px #cd4246, inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-danger:focus{
  -webkit-box-shadow:inset 0 0 0 1px #cd4246, 0 0 0 2px rgba(205, 66, 70, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
          box-shadow:inset 0 0 0 1px #cd4246, 0 0 0 2px rgba(205, 66, 70, 0.3), inset 0 1px 1px rgba(17, 20, 24, 0.2);
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-danger[readonly]{
  -webkit-box-shadow:inset 0 0 0 1px #cd4246;
          box-shadow:inset 0 0 0 1px #cd4246;
}
.bp4-timepicker .bp4-timepicker-input.bp4-intent-danger:disabled, .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger.bp4-disabled{
  -webkit-box-shadow:none;
          box-shadow:none;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger{
  -webkit-box-shadow:0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), inset 0 0 0 1px #e76a6e, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
          box-shadow:0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), 0 0 0 0 rgba(231, 106, 110, 0), inset 0 0 0 1px #e76a6e, inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger:focus{
  -webkit-box-shadow:inset 0 0 0 1px #e76a6e, inset 0 0 0 1px #e76a6e, 0 0 0 2px rgba(231, 106, 110, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
          box-shadow:inset 0 0 0 1px #e76a6e, inset 0 0 0 1px #e76a6e, 0 0 0 2px rgba(231, 106, 110, 0.3), inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger[readonly]{
  -webkit-box-shadow:inset 0 0 0 1px #e76a6e;
          box-shadow:inset 0 0 0 1px #e76a6e;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger:disabled, .bp4-dark .bp4-timepicker .bp4-timepicker-input.bp4-intent-danger.bp4-disabled{
  -webkit-box-shadow:none;
          box-shadow:none;
}
.bp4-timepicker .bp4-timepicker-ampm-select{
  margin-left:5px;
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-input-row{
  background:rgba(211, 216, 222, 0.5);
  -webkit-box-shadow:none;
          box-shadow:none;
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
  resize:none;
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-input-row::-webkit-input-placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-input-row::-moz-placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-input-row:-ms-input-placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-input-row::-ms-input-placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-input-row::placeholder{
  color:rgba(95, 107, 124, 0.6);
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-input,
.bp4-timepicker.bp4-disabled .bp4-timepicker-divider-text{
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}
.bp4-timepicker.bp4-disabled .bp4-timepicker-arrow-button,
.bp4-timepicker.bp4-disabled .bp4-timepicker-arrow-button:hover{
  color:rgba(95, 107, 124, 0.6);
  cursor:not-allowed;
}

.bp4-dark .bp4-timepicker .bp4-timepicker-input-row{
  background:rgba(17, 20, 24, 0.3);
  -webkit-box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
          box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2), inset 0 -1px 1px 0 #8f99a8;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-divider-text{
  color:#abb3bf;
}
.bp4-dark .bp4-timepicker .bp4-timepicker-input{
  color:#f6f7f9;
}

.bp4-datepicker .bp4-timepicker{
  margin-bottom:10px;
  margin-top:5px;
}
.bp4-datepicker .bp4-timepicker:last-child{
  margin-bottom:5px;
}
.bp4-datetimepicker{
  background-color:#fff;
  border-radius:2px;
  padding:10px;
  text-align:center;
}
.bp4-dark .bp4-datetimepicker{
  background:#383e47;
}
.bp4-dark .bp4-datetimepicker .bp4-datepicker{
  border-bottom:1px solid rgba(17, 20, 24, 0.4);
}
.bp4-datetimepicker .bp4-datepicker{
  border-bottom:1px solid rgba(17, 20, 24, 0.15);
  padding:0 0 10px;
}
.bp4-datetimepicker .bp4-timepicker{
  margin-top:10px;
}

.bp4-dateinput-popover{
  padding:0;
}
/*# sourceMappingURL=blueprint-datetime.css.map */