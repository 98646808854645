@-webkit-keyframes skeleton-fade-in{
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}
@keyframes skeleton-fade-in{
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}
@keyframes skeleton-fade-in{
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}
.bp4-table-cell{
  display:inline-block;
  font-size:12px;
  height:20px;
  line-height:20px;
  padding:0 10px;
  -webkit-transition:color 300ms;
  transition:color 300ms;
}
.bp4-dark .bp4-table-cell:not([class*=bp4-intent-]):not(.bp4-loading){
  color:#f6f7f9;
}
.bp4-table-cell.bp4-intent-primary{
  background-color:rgba(45, 114, 210, 0.1);
  color:#2d72d2;
}
.bp4-dark .bp4-table-cell.bp4-intent-primary{
  background:rgba(45, 114, 210, 0.1);
  color:#4c90f0;
}
.bp4-table-cell.bp4-intent-success{
  background-color:rgba(35, 133, 81, 0.1);
  color:#238551;
}
.bp4-dark .bp4-table-cell.bp4-intent-success{
  background:rgba(35, 133, 81, 0.1);
  color:#32a467;
}
.bp4-table-cell.bp4-intent-warning{
  background-color:rgba(200, 118, 25, 0.1);
  color:#c87619;
}
.bp4-dark .bp4-table-cell.bp4-intent-warning{
  background:rgba(200, 118, 25, 0.1);
  color:#ec9a3c;
}
.bp4-table-cell.bp4-intent-danger{
  background-color:rgba(205, 66, 70, 0.1);
  color:#cd4246;
}
.bp4-dark .bp4-table-cell.bp4-intent-danger{
  background:rgba(205, 66, 70, 0.1);
  color:#e76a6e;
}
.bp4-table-editing-enabled .bp4-table-cell{
  cursor:text;
}
.bp4-table-selection-enabled .bp4-table-cell{
  cursor:cell;
}
.bp4-table-cell.bp4-table-truncated-cell{
  overflow:hidden;
}
.bp4-table-cell.bp4-large, .bp4-large .bp4-table-cell{
  font-size:14px;
  height:30px;
  line-height:30px;
}
.bp4-table-cell.bp4-loading{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  color:transparent;
}
.bp4-table-cell:focus{
  outline:none;
}

.bp4-table-cell-interactive{
  z-index:21;
}

.bp4-table-striped .bp4-table-cell-ledger-even{
  background-color:#fff;
}
.bp4-table-striped .bp4-table-cell-ledger-odd{
  background-color:#fbfbfc;
}
.bp4-dark .bp4-table-striped .bp4-table-cell-ledger-even{
  background-color:#2f343c;
}
.bp4-dark .bp4-table-striped .bp4-table-cell-ledger-odd{
  background-color:#343942;
}

.bp4-table-editable-name input{
  height:20px;
}

.bp4-table-editable-text{
  bottom:0;
  left:0;
  padding:0 10px;
  position:absolute;
  right:0;
  top:0;
}
.bp4-table-null{
  color:rgba(95, 107, 124, 0.6);
}

.bp4-table-truncated-value{
  left:10px;
  max-height:100%;
  overflow:hidden;
  position:absolute;
  right:35px;
  text-overflow:ellipsis;
  top:0;
}

.bp4-table-truncated-format-text{
  left:10px;
  max-height:100%;
  overflow:hidden;
  position:absolute;
  right:10px;
  text-overflow:ellipsis;
  top:0;
}

.bp4-table-truncated-popover-target{
  border-radius:3px;
  bottom:0;
  cursor:pointer;
  opacity:0.3;
  padding:0 5px;
  position:absolute;
  right:5px;
  text-align:center;
  top:0;
}
.bp4-table-truncated-popover-target .bp4-icon-standard{
  line-height:20px;
}
.bp4-table-truncated-popover-target.bp4-popover-open{
  opacity:1;
}
.bp4-table-truncated-popover-target.bp4-popover-open .bp4-icon-standard{
  color:#2d72d2;
}
.bp4-table-truncated-popover-target:hover{
  opacity:1;
}

.bp4-table-truncated-popover{
  font-family:monospace;
  max-height:300px;
  max-width:600px;
  min-width:200px;
  overflow:auto;
  padding:10px 10px;
}

.bp4-table-popover-whitespace-pre{
  white-space:pre;
}

.bp4-table-popover-whitespace-normal{
  white-space:normal;
}
.bp4-table-container{
  -webkit-box-shadow:0 0 0 1px rgba(17, 20, 24, 0.15);
          box-shadow:0 0 0 1px rgba(17, 20, 24, 0.15);
}

.bp4-table-menu{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.15), 1px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.15), 1px 0 0 rgba(17, 20, 24, 0.15);
}

.bp4-table-header{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.15);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.15);
}

.bp4-table-cell{
  -webkit-box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.15), inset -1px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.15), inset -1px 0 0 rgba(17, 20, 24, 0.15);
}

.bp4-table-horizontal-cell-divider{
  -webkit-box-shadow:inset 0 1px 0 rgba(17, 20, 24, 0.15);
          box-shadow:inset 0 1px 0 rgba(17, 20, 24, 0.15);
  height:1px;
}

.bp4-table-column-headers .bp4-table-header{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.15), inset -1px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.15), inset -1px 0 0 rgba(17, 20, 24, 0.15);
}
.bp4-table-column-headers .bp4-table-header::before{
  bottom:0;
  right:1px;
}

.bp4-table-row-headers .bp4-table-header{
  -webkit-box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.15), 1px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.15), 1px 0 0 rgba(17, 20, 24, 0.15);
}
.bp4-table-row-headers .bp4-table-header::before{
  bottom:1px;
  right:0;
}

.bp4-table-body .bp4-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.15), 1px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.15), 1px 0 0 rgba(17, 20, 24, 0.15);
}
.bp4-table-body .bp4-table-last-in-column{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.15), inset -1px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.15), inset -1px 0 0 rgba(17, 20, 24, 0.15);
}
.bp4-table-body .bp4-table-last-in-row.bp4-table-last-in-column{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.15), 1px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.15), 1px 0 0 rgba(17, 20, 24, 0.15);
}

.bp4-table-quadrant-top-left .bp4-table-cell.bp4-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.15), inset -3px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.15), inset -3px 0 0 rgba(17, 20, 24, 0.15);
}
.bp4-table-quadrant-top-left .bp4-table-cell.bp4-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.15), inset -1px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.15), inset -1px 0 0 rgba(17, 20, 24, 0.15);
}
.bp4-table-quadrant-top-left .bp4-table-cell.bp4-table-last-in-column.bp4-table-last-in-row{
  -webkit-box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.15), inset -3px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.15), inset -3px 0 0 rgba(17, 20, 24, 0.15);
}
.bp4-table-quadrant-top-left .bp4-table-header.bp4-table-last-in-row{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.15), inset -3px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.15), inset -3px 0 0 rgba(17, 20, 24, 0.15);
}
.bp4-table-quadrant-top-left .bp4-table-header.bp4-table-last-in-row::before{
  bottom:0;
  right:3px;
}
.bp4-table-quadrant-top-left .bp4-table-header.bp4-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.15), 1px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.15), 1px 0 0 rgba(17, 20, 24, 0.15);
}
.bp4-table-quadrant-top-left .bp4-table-header.bp4-table-last-in-column::before{
  bottom:3px;
  right:0;
}

.bp4-table-quadrant-left .bp4-table-cell.bp4-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.15), inset -3px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.15), inset -3px 0 0 rgba(17, 20, 24, 0.15);
}

.bp4-table-quadrant-top .bp4-table-cell.bp4-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.15), inset -1px 0 0 rgba(17, 20, 24, 0.15);
          box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.15), inset -1px 0 0 rgba(17, 20, 24, 0.15);
}

.bp4-dark .bp4-table-container{
  -webkit-box-shadow:0 0 0 1px rgba(17, 20, 24, 0.4);
          box-shadow:0 0 0 1px rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-menu{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.4), 1px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.4), 1px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-header{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.4);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-cell{
  -webkit-box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.4), inset -1px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.4), inset -1px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-horizontal-cell-divider{
  -webkit-box-shadow:inset 0 1px 0 rgba(17, 20, 24, 0.4);
          box-shadow:inset 0 1px 0 rgba(17, 20, 24, 0.4);
  height:1px;
}
.bp4-dark .bp4-table-column-headers .bp4-table-header{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.4), inset -1px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.4), inset -1px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-column-headers .bp4-table-header::before{
  bottom:0;
  right:1px;
}
.bp4-dark .bp4-table-row-headers .bp4-table-header{
  -webkit-box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.4), 1px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.4), 1px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-row-headers .bp4-table-header::before{
  bottom:1px;
  right:0;
}
.bp4-dark .bp4-table-body .bp4-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.4), 1px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.4), 1px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-body .bp4-table-last-in-column{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.4), inset -1px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.4), inset -1px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-body .bp4-table-last-in-row.bp4-table-last-in-column{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.4), 1px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.4), 1px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-quadrant-top-left .bp4-table-cell.bp4-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.4), inset -3px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.4), inset -3px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-quadrant-top-left .bp4-table-cell.bp4-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.4), inset -1px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.4), inset -1px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-quadrant-top-left .bp4-table-cell.bp4-table-last-in-column.bp4-table-last-in-row{
  -webkit-box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.4), inset -3px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.4), inset -3px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-quadrant-top-left .bp4-table-header.bp4-table-last-in-row{
  -webkit-box-shadow:0 1px 0 rgba(17, 20, 24, 0.4), inset -3px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:0 1px 0 rgba(17, 20, 24, 0.4), inset -3px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-quadrant-top-left .bp4-table-header.bp4-table-last-in-row::before{
  bottom:0;
  right:3px;
}
.bp4-dark .bp4-table-quadrant-top-left .bp4-table-header.bp4-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.4), 1px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.4), 1px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-quadrant-top-left .bp4-table-header.bp4-table-last-in-column::before{
  bottom:3px;
  right:0;
}
.bp4-dark .bp4-table-quadrant-left .bp4-table-cell.bp4-table-last-in-row{
  -webkit-box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.4), inset -3px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:inset 0 -1px 0 rgba(17, 20, 24, 0.4), inset -3px 0 0 rgba(17, 20, 24, 0.4);
}
.bp4-dark .bp4-table-quadrant-top .bp4-table-cell.bp4-table-last-in-column{
  -webkit-box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.4), inset -1px 0 0 rgba(17, 20, 24, 0.4);
          box-shadow:inset 0 -3px 0 rgba(17, 20, 24, 0.4), inset -1px 0 0 rgba(17, 20, 24, 0.4);
}
@keyframes skeleton-fade-in{
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}
.bp4-table-header{
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start;
  -webkit-box-flex:1;
      -ms-flex:1 1 auto;
          flex:1 1 auto;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  position:relative;
  text-overflow:ellipsis;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}
.bp4-table-header::before{
  content:"";
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.bp4-table-header .bp4-table-row-name,
.bp4-table-header .bp4-table-column-name,
.bp4-table-header .bp4-table-header-content{
  position:relative;
  width:100%;
}
.bp4-table-selection-enabled .bp4-table-header:hover::before, .bp4-table-header.bp4-table-header-active::before{
  background-color:#e5e8eb;
}
.bp4-dark .bp4-table-selection-enabled .bp4-table-header:hover::before, .bp4-dark .bp4-table-header.bp4-table-header-active::before{
  background-color:#404854;
}
.bp4-table-header.bp4-table-header-selected::before{
  background-image:-webkit-gradient(linear, left top, right top, from(rgba(45, 114, 210, 0.1)), to(rgba(45, 114, 210, 0.1)));
  background-image:linear-gradient(90deg, rgba(45, 114, 210, 0.1), rgba(45, 114, 210, 0.1));
}

.bp4-table-th-menu-container{
  -ms-flex-negative:0;
      flex-shrink:0;
  opacity:0;
  position:absolute;
  right:1px;
  text-align:right;
}
.bp4-table-header:hover .bp4-table-th-menu-container, .bp4-table-header-active .bp4-table-th-menu-container, .bp4-table-th-menu-container.bp4-table-th-menu-open{
  opacity:1;
}
.bp4-table-interaction-bar .bp4-table-th-menu-container{
  line-height:20px;
}

.bp4-table-th-menu-container-background{
  height:30px;
  pointer-events:none;
  position:absolute;
  right:0;
  top:0;
  width:50px;
}
.bp4-table-interaction-bar .bp4-table-th-menu-container-background{
  height:20px;
}
.bp4-table-th-menu-open .bp4-table-th-menu-container-background{
  background-image:-webkit-gradient(linear, left top, right top, from(rgba(246, 247, 249, 0)), color-stop(50%, #f6f7f9));
  background-image:linear-gradient(90deg, rgba(246, 247, 249, 0) 0%, #f6f7f9 50%);
}
.bp4-dark .bp4-table-th-menu-open .bp4-table-th-menu-container-background{
  background-image:-webkit-gradient(linear, left top, right top, from(rgba(56, 62, 71, 0)), color-stop(50%, #383e47));
  background-image:linear-gradient(90deg, rgba(56, 62, 71, 0) 0%, #383e47 50%);
}
.bp4-table-header:hover .bp4-table-th-menu-container-background, .bp4-table-header-active .bp4-table-th-menu-container-background, .bp4-table-th-menu-open .bp4-table-th-menu-container-background{
  background-image:-webkit-gradient(linear, left top, right top, from(rgba(246, 247, 249, 0)), color-stop(50%, #f6f7f9));
  background-image:linear-gradient(90deg, rgba(246, 247, 249, 0) 0%, #f6f7f9 50%);
}
.bp4-dark .bp4-table-header:hover .bp4-table-th-menu-container-background, .bp4-dark .bp4-table-header-active .bp4-table-th-menu-container-background, .bp4-dark .bp4-table-th-menu-open .bp4-table-th-menu-container-background{
  background-image:-webkit-gradient(linear, left top, right top, from(rgba(56, 62, 71, 0)), color-stop(50%, #383e47));
  background-image:linear-gradient(90deg, rgba(56, 62, 71, 0) 0%, #383e47 50%);
}
.bp4-table-selection-enabled .bp4-table-header:hover .bp4-table-th-menu-container-background, .bp4-table-selection-enabled .bp4-table-header-active .bp4-table-th-menu-container-background{
  background-image:-webkit-gradient(linear, left top, right top, from(rgba(229, 232, 235, 0)), color-stop(50%, #e5e8eb));
  background-image:linear-gradient(90deg, rgba(229, 232, 235, 0) 0%, #e5e8eb 50%);
}
.bp4-dark .bp4-table-selection-enabled .bp4-table-header:hover .bp4-table-th-menu-container-background, .bp4-dark .bp4-table-selection-enabled .bp4-table-header-active .bp4-table-th-menu-container-background{
  background-image:-webkit-gradient(linear, left top, right top, from(rgba(64, 72, 84, 0)), color-stop(50%, #404854));
  background-image:linear-gradient(90deg, rgba(64, 72, 84, 0) 0%, #404854 50%);
}
.bp4-table-selection-enabled .bp4-table-header.bp4-table-header-selected .bp4-table-th-menu-container-background{
  background-image:-webkit-gradient(linear, left top, right top, from(rgba(226, 234, 245, 0)), color-stop(50%, #e2eaf5));
  background-image:linear-gradient(90deg, rgba(226, 234, 245, 0) 0%, #e2eaf5 50%);
}
.bp4-dark .bp4-table-selection-enabled .bp4-table-header.bp4-table-header-selected .bp4-table-th-menu-container-background{
  background-image:-webkit-gradient(linear, left top, right top, from(rgba(55, 67, 85, 0)), color-stop(50%, #374355));
  background-image:linear-gradient(90deg, rgba(55, 67, 85, 0) 0%, #374355 50%);
}
.bp4-table-selection-enabled .bp4-table-header.bp4-table-header-selected:hover .bp4-table-th-menu-container-background{
  background-image:-webkit-gradient(linear, left top, right top, from(rgba(211, 220, 233, 0)), color-stop(50%, #d3dce9));
  background-image:linear-gradient(90deg, rgba(211, 220, 233, 0) 0%, #d3dce9 50%);
}
.bp4-dark .bp4-table-selection-enabled .bp4-table-header.bp4-table-header-selected:hover .bp4-table-th-menu-container-background{
  background-image:-webkit-gradient(linear, left top, right top, from(rgba(62, 76, 97, 0)), color-stop(50%, #3e4c61));
  background-image:linear-gradient(90deg, rgba(62, 76, 97, 0) 0%, #3e4c61 50%);
}

.bp4-table-th-menu{
  cursor:pointer;
  height:30px;
  position:relative;
  width:30px;
}
.bp4-table-interaction-bar .bp4-table-th-menu{
  height:20px;
  right:1px;
  text-align:center;
  width:20px;
}
.bp4-table-interaction-bar .bp4-table-th-menu .bp4-icon{
  margin:2px;
  margin-left:3px;
  vertical-align:top;
}
.bp4-table-th-menu .bp4-icon{
  background-color:#f6f7f9;
  border-radius:2px;
  -webkit-box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2);
          box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.2);
  color:#5f6b7c;
  margin-right:7px;
  margin-top:7px;
}
.bp4-table-selection-enabled .bp4-table-th-menu .bp4-icon{
  background-color:#e5e8eb;
}
.bp4-table-selection-enabled .bp4-table-header.bp4-table-header-selected .bp4-table-th-menu .bp4-icon{
  background-color:linear-gradient(90deg, rgba(226, 234, 245, 0) 0%, #e2eaf5 50%);
}
.bp4-dark .bp4-table-th-menu .bp4-icon{
  background-color:#383e47;
  -webkit-box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2);
          box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  color:#abb3bf;
}
.bp4-dark .bp4-table-selection-enabled .bp4-table-th-menu .bp4-icon{
  background-color:#404854;
}
.bp4-dark .bp4-table-selection-enabled .bp4-table-header.bp4-table-header-selected .bp4-table-th-menu .bp4-icon{
  background-color:linear-gradient(90deg, rgba(55, 67, 85, 0) 0%, #374355 50%);
}
.bp4-table-th-menu:hover .bp4-icon{
  -webkit-box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.4);
          box-shadow:inset 0 0 0 1px rgba(17, 20, 24, 0.4);
  color:#1c2127;
}
.bp4-dark .bp4-table-th-menu:hover .bp4-icon{
  -webkit-box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.4);
          box-shadow:inset 0 0 0 1px rgba(255, 255, 255, 0.4);
  color:#f6f7f9;
}
.bp4-table-th-menu.bp4-popover-open .bp4-icon{
  background-color:#2d72d2;
  -webkit-box-shadow:none;
          box-shadow:none;
  color:#fff;
}
.bp4-dark .bp4-table-th-menu.bp4-popover-open .bp4-icon{
  background-color:#2d72d2;
  -webkit-box-shadow:none;
          box-shadow:none;
  color:#fff;
}

.bp4-table-thead{
  display:block;
  white-space:nowrap;
}

.bp4-table-column-header-tr{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}
.bp4-table-column-header-tr .bp4-table-header{
  -webkit-box-flex:0;
      -ms-flex:0 0;
          flex:0 0;
}

.bp4-table-column-headers .bp4-table-interaction-bar{
  height:20px;
  position:relative;
}

.bp4-table-column-headers .bp4-table-header{
  line-height:30px;
  min-height:30px;
  vertical-align:top;
}

.bp4-table-row-headers .bp4-table-header{
  line-height:20px;
  min-width:30px;
  overflow:hidden;
}

.bp4-table-column-name-text,
.bp4-table-row-name-text{
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;
  pointer-events:none;
}

.bp4-table-truncated-text{
  max-height:100%;
  overflow:hidden;
  text-overflow:ellipsis;
}

.bp4-table-no-wrap-text{
  white-space:nowrap;
}

.bp4-table-column-name-text{
  padding:0 10px;
}

.bp4-table-editable-name{
  display:block;
  pointer-events:all;
}
.bp4-table-editable-name.bp4-editable-text::before{
  border-radius:0;
  bottom:0;
  left:-11px;
  right:-10px;
  top:-1px;
}
.bp4-table-editable-name.bp4-editable-text:not(.bp4-editable-editing)::before{
  -webkit-box-shadow:none;
          box-shadow:none;
}
.bp4-table-editable-name.bp4-editable-text.bp4-editable-editing::before{
  bottom:1px;
  cursor:text;
  left:-10px;
  right:-9px;
  top:0px;
}
.bp4-table-editable-name.bp4-editable-text.bp4-editable-editing.bp4-table-editable-text::before{
  left:0px;
  right:1px;
}
.bp4-table-column-name .bp4-table-editable-name.bp4-editable-text::before{
  bottom:-1px;
}
.bp4-table-column-name .bp4-table-editable-name.bp4-editable-text.bp4-editable-editing::before{
  bottom:0;
}

.bp4-table-column-name-text .bp4-table-editable-name input{
  height:30px;
}

.bp4-table-column-name{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  font-size:14px;
  text-align:left;
}

.bp4-table-row-name{
  display:block;
  font-size:12px;
  padding:0 5px;
  text-align:right;
}

.bp4-table-header-content{
  font-size:14px;
  white-space:normal;
}
.bp4-table-header-content .is-searchable:not(.is-focused) > .Select-control{
  background:none;
  -webkit-box-shadow:none;
          box-shadow:none;
}
.bp4-table-header-content .is-searchable > .Select-control{
  border-radius:0;
}
.bp4-table-header-content .is-searchable > .Select-control .Select-value{
  cursor:pointer;
}
.bp4-table-header-content .Select-value{
  right:-1px;
}

.bp4-table-column-name,
.bp4-table-row-name{
  -webkit-transition:color 300ms;
  transition:color 300ms;
}

.bp4-table-header.bp4-loading{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
}
.bp4-table-header.bp4-loading .bp4-table-column-name,
.bp4-table-header.bp4-loading .bp4-table-row-name{
  -webkit-box-flex:1;
      -ms-flex:1;
          flex:1;
}
.bp4-table-header.bp4-loading .bp4-table-column-name-text{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  padding:10px;
}
.bp4-table-header.bp4-loading .bp4-table-column-name-text .bp4-skeleton{
  height:8px;
}
.bp4-table-header.bp4-loading .bp4-table-row-name{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
}
.bp4-table-selection-enabled.bp4-table-column-headers .bp4-table-header{
  cursor:s-resize;
}
.bp4-table-selection-enabled.bp4-table-column-headers .bp4-table-header.bp4-table-header-reorderable{
  cursor:-webkit-grab;
  cursor:grab;
}
.bp4-table-selection-enabled.bp4-table-column-headers .bp4-table-header.bp4-table-header-reorderable:active{
  cursor:-webkit-grabbing;
  cursor:grabbing;
}
.bp4-table-selection-enabled.bp4-table-column-headers .bp4-table-header.bp4-table-header-reorderable .bp4-table-interaction-bar{
  cursor:-webkit-grab;
  cursor:grab;
}
.bp4-table-selection-enabled.bp4-table-column-headers .bp4-table-header.bp4-table-header-reorderable .bp4-table-interaction-bar:active{
  cursor:-webkit-grabbing;
  cursor:grabbing;
}
.bp4-table-selection-enabled.bp4-table-row-headers .bp4-table-header{
  cursor:e-resize;
}
.bp4-table-selection-enabled.bp4-table-row-headers .bp4-table-header.bp4-table-header-reorderable{
  cursor:-webkit-grab;
  cursor:grab;
}
.bp4-table-selection-enabled.bp4-table-row-headers .bp4-table-header.bp4-table-header-reorderable:active{
  cursor:-webkit-grabbing;
  cursor:grabbing;
}
.bp4-table-selection-enabled.bp4-table-row-headers .bp4-table-header.bp4-table-header-reorderable .bp4-table-interaction-bar{
  cursor:-webkit-grab;
  cursor:grab;
}
.bp4-table-selection-enabled.bp4-table-row-headers .bp4-table-header.bp4-table-header-reorderable .bp4-table-interaction-bar:active{
  cursor:-webkit-grabbing;
  cursor:grabbing;
}
.bp4-table-selection-enabled.bp4-table-menu{
  cursor:se-resize;
}
.bp4-table-selection-enabled .bp4-editable-text::before,
.bp4-table-selection-enabled .bp4-editable-content{
  cursor:cell;
}
.bp4-table-column-header-cell.bp4-table-has-reorder-handle:not(.bp4-table-has-interaction-bar) .bp4-table-column-name-text{
  padding-left:22px;
}
.bp4-table-column-header-cell.bp4-table-has-reorder-handle:not(.bp4-table-has-interaction-bar) .bp4-table-editable-name::before{
  left:-22px;
}

.bp4-table-reorder-handle-target{
  cursor:-webkit-grab;
  cursor:grab;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  bottom:0;
  color:rgba(95, 107, 124, 0.6);
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  left:0;
  position:absolute;
  top:0;
  width:22px;
}
.bp4-table-reorder-handle-target:active{
  cursor:-webkit-grabbing;
  cursor:grabbing;
}
.bp4-table-reorder-handle-target:hover{
  color:#1c2127;
}
.bp4-table-reorder-handle-target:active{
  color:#2d72d2;
}
.bp4-dark .bp4-table-reorder-handle-target{
  color:rgba(171, 179, 191, 0.6);
}
.bp4-dark .bp4-table-reorder-handle-target:hover{
  color:#f6f7f9;
}
.bp4-dark .bp4-table-reorder-handle-target:active{
  color:#2d72d2;
}

.bp4-table-reorder-handle{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}

.bp4-table-resize-handle-target{
  opacity:0;
  position:absolute;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  z-index:20;
}
.bp4-table-resize-handle-target:hover, .bp4-table-resize-handle-target.bp4-table-dragging{
  opacity:1;
}
.bp4-table-resize-handle-target.bp4-table-resize-vertical{
  bottom:-1px;
  cursor:ew-resize;
  right:0;
  top:0;
  width:5px;
}
.bp4-table-resize-handle-target.bp4-table-resize-horizontal{
  bottom:0;
  cursor:ns-resize;
  height:5px;
  left:0;
  right:-1px;
}

.bp4-table-resize-handle{
  background-color:#2d72d2;
  position:absolute;
  z-index:20;
}
.bp4-table-resize-handle.bp4-table-dragging{
  background-color:#2d72d2;
}

.bp4-table-resize-vertical .bp4-table-resize-handle{
  bottom:0;
  left:2px;
  top:0;
  width:3px;
}

.bp4-table-resize-horizontal .bp4-table-resize-handle{
  height:3px;
  left:0;
  right:0;
  top:2px;
}

.bp4-table-resize-guides .bp4-table-horizontal-guide{
  background-color:#2d72d2;
  height:3px;
  margin-top:-3px;
}
.bp4-table-resize-guides .bp4-table-horizontal-guide.bp4-table-horizontal-guide-flush-top{
  margin-top:0;
}

.bp4-table-resize-guides .bp4-table-vertical-guide{
  background-color:#2d72d2;
  margin-left:-3px;
  width:3px;
}
.bp4-table-resize-guides .bp4-table-vertical-guide.bp4-table-vertical-guide-flush-left{
  margin-left:0;
}
.bp4-table-overlay-layer{
  bottom:0;
  left:0;
  overflow:hidden;
  pointer-events:none;
  position:absolute;
  right:0;
  top:0;
  z-index:20;
}

.bp4-table-overlay{
  position:absolute;
}

.bp4-table-region{
  background-color:rgba(143, 153, 168, 0.1);
  border:1px solid #8f99a8;
}

.bp4-table-selection-region{
  background-color:rgba(45, 114, 210, 0.1);
  border:1px solid #2d72d2;
}
.bp4-dark .bp4-table-selection-region{
  background-color:rgba(76, 144, 240, 0.1);
  border:1px solid #4c90f0;
}
.bp4-table-column-headers .bp4-table-selection-region{
  background-color:transparent;
}
.bp4-table-row-headers .bp4-table-selection-region{
  background-color:transparent;
}

.bp4-table-focus-region{
  border:2px solid #2d72d2;
}

.bp4-table-column-headers .bp4-table-region{
  border-bottom:none;
}

.bp4-table-row-headers .bp4-table-region{
  border-right:none;
}

.bp4-table-vertical-guide{
  bottom:0;
  top:0;
}

.bp4-table-horizontal-guide{
  left:0;
  right:0;
}

.bp4-table-reordering-cursor-overlay{
  cursor:-webkit-grabbing;
  cursor:grabbing;
}
.bp4-table-reordering .bp4-table-reordering-cursor-overlay{
  pointer-events:all;
}
.bp4-table-quadrant-stack{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  height:100%;
  position:relative;
}

.bp4-table-quadrant{
  background:#f6f7f9;
  left:0;
  overflow:hidden;
  position:absolute;
  top:0;
}
.bp4-dark .bp4-table-quadrant{
  background-color:#383e47;
}

.bp4-table-quadrant-scroll-container{
  bottom:0;
  left:0;
  overflow:auto;
  position:relative;
  right:0;
  top:0;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  will-change:transform;
}
.bp4-table-no-vertical-scroll .bp4-table-quadrant-scroll-container{
  overflow-y:hidden;
}
.bp4-table-no-horizontal-scroll .bp4-table-quadrant-scroll-container{
  overflow-x:hidden;
}

.bp4-table-quadrant-body-container{
  position:relative;
}

.bp4-table-quadrant-main{
  height:100%;
  left:auto;
  position:relative;
  top:auto;
  width:100%;
  z-index:0;
}
.bp4-table-quadrant-main .bp4-table-quadrant-scroll-container{
  height:100%;
  width:100%;
}
.bp4-table-quadrant-main .bp4-table-cell-client{
  background:#fff;
}

.bp4-table-quadrant-top{
  right:0;
  z-index:1;
}
.bp4-table-quadrant-top .bp4-table-quadrant-scroll-container{
  bottom:-20px;
  overflow-y:hidden;
}

.bp4-table-quadrant-left{
  bottom:0;
  -webkit-transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  z-index:2;
}
.bp4-table-quadrant-left .bp4-table-quadrant-scroll-container{
  bottom:0;
  height:auto;
  overflow-x:hidden;
  position:absolute;
  right:-20px;
  top:0;
}
.bp4-table-quadrant-left .bp4-table-body-virtual-client{
  min-width:1px;
}

.bp4-table-quadrant-top-left{
  -webkit-transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  z-index:3;
}
.bp4-table-quadrant-top-left .bp4-table-quadrant-scroll-container{
  bottom:-20px;
  overflow-x:hidden;
  overflow-y:hidden;
  right:-20px;
}
.bp4-table-quadrant-top-left .bp4-table-body-virtual-client{
  min-width:1px;
}

.bp4-table-container{
  background-color:#f6f7f9;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  height:100%;
  max-height:100%;
  max-width:100%;
  min-height:60px;
  overflow:hidden;
  will-change:transform;
}
.bp4-dark .bp4-table-container{
  background-color:#383e47;
}
.bp4-table-container .bp4-loading{
  color:transparent;
}
.bp4-table-container .bp4-loading .bp4-skeleton{
  -webkit-animation:300ms linear forwards skeleton-fade-in, 1000ms linear infinite alternate skeleton-glow;
          animation:300ms linear forwards skeleton-fade-in, 1000ms linear infinite alternate skeleton-glow;
  -webkit-animation-delay:0s, 300ms;
          animation-delay:0s, 300ms;
  height:5px;
  opacity:0;
}

.bp4-table-top-container{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:0;
      -ms-flex:0 0 auto;
          flex:0 0 auto;
  min-height:0%;
}
.bp4-table-container.bp4-table-no-rows .bp4-table-top-container{
  padding-bottom:1px;
}

.bp4-table-bottom-container{
  color:#1c2127;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:1;
      -ms-flex:1 1 auto;
          flex:1 1 auto;
  height:100%;
  min-height:0%;
}
.bp4-dark .bp4-table-bottom-container{
  color:#f6f7f9;
}

.bp4-table-menu{
  background-color:#f6f7f9;
  -webkit-box-flex:0;
      -ms-flex:0 0 auto;
          flex:0 0 auto;
  position:relative;
  z-index:13;
}
.bp4-dark .bp4-table-menu{
  background-color:#383e47;
}

.bp4-table-column-headers{
  background-color:#f6f7f9;
  color:#1c2127;
  display:block;
  position:relative;
  z-index:11;
}
.bp4-dark .bp4-table-column-headers{
  background-color:#383e47;
  color:#f6f7f9;
}

.bp4-table-row-headers{
  background-color:#f6f7f9;
  color:#5f6b7c;
  -webkit-box-flex:0;
      -ms-flex:0 0 auto;
          flex:0 0 auto;
  position:relative;
  -webkit-transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition:width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  z-index:12;
}
.bp4-dark .bp4-table-row-headers{
  background-color:#383e47;
  color:#abb3bf;
}

.bp4-table-body{
  -webkit-box-flex:1;
      -ms-flex:1 1 100%;
          flex:1 1 100%;
  overflow:scroll;
  position:relative;
  z-index:10;
}

.bp4-table-body-virtual-client{
  position:relative;
}

.bp4-table-cell-client{
  background:#fff;
}
.bp4-dark .bp4-table-cell-client{
  background:#2f343c;
}

.bp4-table-tbody{
  display:block;
  white-space:nowrap;
}

.bp4-table-cell{
  display:inline-block;
}

.bp4-table-no-layout{
  display:inline-block;
  position:absolute;
}

.bp4-table-scrollbar-measure{
  height:100px;
  overflow:scroll;
  position:absolute;
  top:-9999px;
  width:100px;
}
/*# sourceMappingURL=table.css.map */