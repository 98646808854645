// Path selector related CSS
.pathselector-icon {
  display: inline-block;
  margin-right: 5px;
}

.pathselector {
  padding: 0px 10px;
  border-bottom: 6px solid #ccc;
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;

  &:hover {
    color: #000;
    background-color: #f1f1f1;
  }
}

.pathselector-clicked {
  border-color: #f44336;
}
