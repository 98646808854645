// File browser container
.filebrowser-dialog[role="dialog"] {
  position: fixed;
  width: 80%;
  margin: auto;
  color: #182026;
}

.filebrowser {
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px;
  width: 100%;
}

.filebrowser-input {
  display: flex;
  margin: 0;
  width: 100%;

  .filebrowser-label {
    display: inline-block;
    width: 93.3%;
  }
}

.filebrowser-span {
  display: inline-block;
  width: 10%;
  font-size: 15px;
  vertical-align: middle;
}

.filebrowser-field {
  display: inline-block;
  width: 78%;
  vertical-align: middle;
}

.filebrowser-field.tiny:disabled {
  color: #182026;
  width: 28%;
}

.filebrowser-field.small {
  width: 50%;
}

.filebrowser-select {
  padding-left: 5px;
  padding-right: 5px;
  height: 30px;
  font-style: italic;
}

.filebrowser-button {
  margin-bottom: 15px;
  padding: 0px 10px;
  width: 15%;
  height: 30px;
}

@media only screen and (min-width: 1200px) {
  .filebrowser-button {
    width: 120px;
  }
}
