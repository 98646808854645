.time-slider {
  position: relative;

  & input[type="range"] {
    height: 20px;
    -webkit-appearance: none;
    margin: 0;
  }
  & input[type="range"]:focus {
    outline: none;
  }
  & input[type="range"]::-webkit-slider-runnable-track {
    cursor: pointer;
    border-radius: 1px;
  }

  & input[type="range"]::-webkit-slider-thumb {
    height: 20px;
    width: 4px;
    background: #333333;
    cursor: pointer;
    -webkit-appearance: none;
  }
  & input[type="range"]::-moz-range-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    border-radius: 1px;
  }
  & input[type="range"]::-moz-range-thumb {
    border: 0px;
    height: 20px;
    width: 4px;
    border-radius: 14px;
    background: #333333;
    cursor: pointer;
  }
  & input[type="range"]::-ms-thumb {
    height: 20px;
    width: 4px;
    background: #333333;
    cursor: pointer;
  }
  & input[type="range"]:focus::-ms-fill-lower {
    background: transparent;
  }
  & input[type="range"]:focus::-ms-fill-upper {
    background: transparent;
  }

  & text {
    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16",
      sans-serif;
    font-size: 12px;
    font-weight: 400;
  }

  & .focus-bottom-axis g line,
  .focus-bottom-axis g circle,
  .focus-bottom-axis g path {
    fill: none;
    stroke: #ccc;
  }

  & svg {
    font: 10px sans-serif;
  }
}
