.legendtext {
    font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', 'Icons16', sans-serif !important;
    font-size: "12px";
}
.focus-filter3D {
    display: flex;
    justify-content: flex-end;
    height: fit-content;
    z-index:10;
    width: inherit;
}

.position-tooltip {
    display: flex;
    flex-direction: column;
    font-family: monospace;
    z-index: 1;
    padding: 10px;
    height: fit-content;
    min-width: max-content;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}