@import "~react-virtualized/styles.css";

.GroupableTable-row {
  display: flex;
  flex-wrap: nowrap;
  border-top: thin solid #dcdcdc;
  height: 30px;
  align-items: center;
}

.GroupableTable-row:hover {
  background-color: #f5f5f5;
}

.GroupableTable-rowHeader {
  display: flex;
  flex-wrap: nowrap;
  height: 30px;
  align-items: center;
  color: #a9a9a9;
  flex: auto;
  font-weight: 400;
  text-transform: none;
  justify-content: space-between;
}

.labelCells {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  min-width: 0px;
}

.eventCell {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 3px;
  padding-right: 3px;
  flex: 0 1 Auto;
  width: 11.5%;
}

.eventActions {
  text-align: right;
  display: flex;
  width: Auto;
  flex: 0 1 Auto;
}

.eventAction {
  padding-left: 5px;
}

.pt-button.actionButton {
  min-width: 16px;
  min-height: 16px;
}

.DragHandle {
  flex: 0 0 16px;
  z-index: 2;
  cursor: col-resize;
  color: #a9a9a9;
  opacity: 0.5;
}
.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.DragHandleActive,
.DragHandleActive:hover {
  color: #0b6fcc;
  z-index: 3;
}

.DragHandleIcon {
  flex: 0 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GroupableTable_table {
  max-width: 100%;
  overflow-y: auto;
}

.ReactVirtualized__Table__headerColumn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

.ReactVirtualized__Table__headerTruncatedText {
  flex: auto;
}

.events-logger-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden; //Necessary for correct rendering on Firefox[tested on 62.0.2]. Chrome's rendered page stands unchanged regardless of this property.
}
.GroupableTable-hover-row > .expandable-autofocus-row {
  display: grid;
  grid-template-rows: 30px 1fr;
}
.copyDatasetHistory-table {
  margin: 3px;
}

.ReactVirtualized__Grid::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.ReactVirtualized__Grid::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ReactVirtualized__Grid::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.ReactVirtualized__Grid::-webkit-scrollbar-thumb:hover {
  background: #555;
}
