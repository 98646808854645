.legend {
  font-size: 0.71em;
}

.chart {
  width: 80%;
  height: 500px;
}

.plot-container {
  position: relative;
}

.Responsive-wrapper {
  width: 100%;
  height: 100%;
}

.focus-grafos-grid line {
  stroke: #ddd;
}

.focus-grafos-grid path {
  stroke-width: 0;
}

.plot-base {
  position: relative;
}

.dot-sign {
  border-radius: 50%;
  display: inline-block;
  height: 11px;
  margin-right: 4px;
  width: 11px;
  border-width: 2px;
  border-style: solid;
}

.filter-input-container {
  display: flex;
  width: 185px;
  padding: 0;
  margin: 0 0 5px 0;
}

.hidden {
  display: none;
}

.no-background {
  background: none !important;
}

.mainSvg {
  display: inline-block;
  position: absolute;
}

.brushSvg {
  display: inline-block;
  position: absolute;
}

.zoomSvg {
  display: inline-block;
  position: absolute;
  cursor: grab;

  &.cross {
    cursor: crosshair;
  }

  z-index: 1;
}

.graph_top-area {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  & h3 {
    margin: 0px;
  }

}

.general-plot {
  display: flex;
}

.focusGrafos-graph_top-area .save-button-plot {
  position: absolute;
  top: 0px;
}

.focusGrafos-graph_title {
  text-align: center;
  flex-grow: 2;
}

.fixed-tooltip-right {
  font-family: monospace;
  background: white;
  padding: 10px;
  margin: 5px;
  height: fit-content;
  min-width: max-content;
  border-radius: 2px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  position: absolute;
  & > span {
    display: flex;
  }

  &--closed{
    position: relative;
    // left: ;
  }
}

.show-hover {
  opacity: 0;

  &:hover {
    opacity: 1
  }
}

svg {
  title {
    content: "";
  }
}

.graph-right-side {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
}