.error-panel {
  background-color: #fff;
  border-color: #ebccd1;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

.error-title {
  color: #a94442;
  cursor: pointer;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.error-title-text {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.error-list-component {
  margin-top: 0px;
}

.error-list-component-item {
  margin-left: 30%;
}

.error-icon {
  margin-left: -3px;
  margin-right: 3px;
  margin-bottom: 3px;
  vertical-align: middle !important;
}