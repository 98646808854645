.focus-bottom-axis {  
  & text {
    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif;
    font-size: 12px;
    font-weight: 400;
  }
  
  & line,
  circle,
  path {
    fill: none;
    stroke: #CCC;
  }
  
  & svg {
  font: 10px sans-serif;
  }
}


