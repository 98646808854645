.change-panel {
  margin-bottom: 20px;
  background-color: rgb(255, 255, 255);
  border-color: #cfebcc;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

.change-title {
  color: #4ba942;
  cursor: pointer;
  background-color: #e1f2de;
  border-color: #d1ebcc;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.change-title-text {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.change-list-component {
  margin-top: 0px;
}

.change-list-component-item {
  margin-left: 30%;
}

.change-icon {
  margin-left: -3px;
  margin-right: 3px;
  margin-bottom: 3px;
  vertical-align: middle !important;
}