.list-table-container {
  margin-top: 5px;
  display: "inline-block";
  width: 100%;
  padding-bottom: 10px;
}

.list-table-subcontainer {
  display: flex;
  align-items: center;
  height: 30px;
}

.list-button-container {
  display: inline-block;
  padding-right: 10px;
}

.list-table-button-item {
  display: inline-block;
}

.list-label-container {
  display: inline-block;
  height: 100%;
}