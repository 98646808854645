.loading-component{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: 100%;
    width: 100%;
    &_heading{
        color: #5f6b7c;
        line-height: 20px;
        font-size: 18px;
        font-weight: 600;
    }
}