.matrixlist-div {
    margin-top: 5px;
    max-height: 500px;
    margin-bottom: 15px;
    min-height: 79px; 
    &.shrink{
      height: 500px;
    }
    &.oneRow{
      height: 62px;
      margin-bottom: 30px;
    }
    .ag-header {
      background-color: #f5f8fa;
    }
    &.disabled{
        background-color: #F6F6F6 !important;
    }
  }