.legend-container {
  margin-top: 5px;
  display: flex;
  justify-content: space-around;
  width: 225px;
  height: 100%;
  right: 0px;
}
.legend-container-use {
  background-color: white;
}
.legend-container-unuse {
  pointer-events: none;
}
.legend-container-left {
  width: 185px;
}
.legend-toggler {
  height: 100%;
  min-width: 25px;
  min-height: 50px;
  border-radius: 3px;
  background-color: rgba($color: #f5f8fa, $alpha: 0.7) !important;
  background-image: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: solid 1px rgb(0 0 0 / 10%);
}
.legend-toggler:hover {
  background-color: rgba(115, 134, 148, 0.3) !important;
}
.legend-tooltip {
  height: 100%;
  right: 2px;
  pointer-events: all;

  > span {
    height: 100%;
  }
}
.legend {
  display: inline-block;
  width: 185px;
  overflow-y: hidden;
  overflow-x: auto;
}
.legend-variable {
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16",
    sans-serif;
  font-size: 12px;
  padding: 2px 0;
}
.legend-clickable {
  cursor: pointer;
}
.legend-svg {
  margin-right: 5px;
}

.yAxis-identifier {
  margin-left: 3px;
  color: #2d72d2;
  font-style: italic;
}
.legend-disabled {
  color: #c2c2c2;
}

.printable-legend {
  display: flex;
  flex-flow: column wrap;
  column-gap: 15px;
  height: inherit;
}
