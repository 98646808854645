
$hamburger-padding-x: 15px ;
$hamburger-padding-y: 5px ;

$hamburger-layer-width: 30px ;
$hamburger-layer-spacing: 3px ;

$hamburger-layer-color: #f5f8fa;
@import "./hamburgers/hamburgers.scss";

.hamburger{
        background-color: #333;
        outline-color: rgba(0, 0, 0, 0.0);
}