.focus-grafosConfig {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch ;
    & > div {
        flex: 50%;
        padding : 5px;
    }
    & > .focus-grafosConfig_leftPanel {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}

.focus-grafosDateInput {
    display: flex;
    flex-direction: row;
}
.delete-object {
    margin: 0px 6px 6px 2px;
}