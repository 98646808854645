.error-component{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: 100%;
    width: 100%;
    &_heading{
        line-height: 20px;
        font-size: 18px;
        font-weight: 600;
    }
}

.error-text {
    font-size: 60px
}