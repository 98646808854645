.var_list-name {
  min-width: 350px;
  max-width: 350px;
  a:hover {
    text-decoration: none;
    cursor: default;
  }
}

.var_list-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.var_list-short {
  min-width: 200px;
  max-width: 200px;
  margin-left: 10px;
}
.var_list-type {
  min-width: 80px;
}
.var_list-container ul {
  margin-bottom: 5px;
}

.var_list-container li > div:nth-child(1) {
  height: 40px;
}
