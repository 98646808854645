@import "@focus/focusUI/lib/style";

.form-group legend {
  font-size: 25px;
}

.program-button_container {
  display: flex;
  justify-content: center;
}

.program-label_modification {
  color: #999;
  font-size: 14px;
  display: flex;
  padding-left: 10px;
}

.form-container {
  padding: 5px;
  height: 80vh;
  overflow-y: auto;
  &.in-dialog {
    height: calc(95vh - 200px);
  }
}

.hidden {
  display: none;
}

.separator-form fieldset {
  border: none;
}

.panel-ref-status-label {
  font-size: 14px;
  display: inline;
  padding-left: 10px;
  color: rgb(255, 124, 2);
}

.panel-ref-status-label-ok {
  font-size: 14px;
  display: inline;
  padding-left: 10px;
  color: rgb(10, 161, 10);
}
