.centered-separator {
  text-align: center;
}

div.centered-separator > label {
  margin: 3px 0;
}

.inlined-numeric {
  display: inline-block;
}

.inlined-middle {
  display: flex;
  align-items: center;

  .lower-checkbox {
    margin-bottom: 0;
  }
}
.inlined-string {
  display: flex;
  flex: 1 1 auto;
}

.limited-tooltip {
  max-width: 500px;
}

.fc-string-selector {
  flex: 1 1 auto;
}
.fw-flex-column {
  flex-direction: column;
}

.fw-flex-container {
  display: flex;

  &--end {
    align-items: flex-end;
  }
}

.fw-copy-paste-button-container {
  margin: 2px 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.fw-copy-paste-button {
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
}

.fw-form-field {
  align-items: center;
  margin: 0;
}

.fw-flex-expand {
  flex: 1 1 auto;
}
label.fw-flexible-label {
  display: flex;
  flex: 1 1 auto;
  margin: 3px 0;
}

.inlined-middle {
  display: flex;
  align-items: center;
}

.inlined-start {
  display: flex;
  align-items: flex-start;
}

.inlined-units {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.flex-parent {
  display: flex;
  align-items: left;
}

.flex-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &-icon {
    min-width: 24px;
    margin-left: auto;
  }
}

.select-custom {
  display: block;
}

.inlined-select {
  display: inline-block;
  margin-bottom: 15px;
}

.time-input-div {
  display: flex;
  position: relative;
}

.inlined-epoch-group {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 15px;
}

.epoch-absolute-div {
  display: flex;
  position: relative;
}

.epoch-absolute-input {
  display: inline-block;
  width: 195px;
  padding-right: 0;
}

.form-container {
  span[icon] {
    height: 15px;
    width: 15px;
  }
  input:disabled {
    cursor: default;
  }
}

input.right-aligned {
  direction: ltr;
  overflow: hidden;
}

input.right-aligned:not(:focus) {
  direction: rtl;
  text-align: left;
  unicode-bidi: plaintext;
  text-overflow: ellipsis;
}

.text-danger {
  color: #a94442;
}

.array-label {
  height: 30px;
  padding-top: 6px;
  padding-left: 2%;
  padding-right: 2%;
  color: #5c7080;
  display: inline-block;
  text-align: right;
  vertical-align: middle;
}

.array-units {
  width: 20%;
  padding-left: 5px;
  display: inline-block;
}

.array-color {
  display: flex;
  background-color: #f5f8fa;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15);
}

.array-row {
  width: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fw-menu-icon {
  margin: auto;
}

.fw-form-break {
  margin: 12px 0px;
}

.program-lock-status {
  margin-left: 10px;
}

.form-container .tabHolder input {
  margin-top: 0;
}

.form-container .fw-flex-container div {
  margin-top: 0;
}

.form-container .inlined-string div {
  margin-top: 0;
}

.dateInput-with-clear {
  display: flex;
  flex-direction: row;
}
