.focus-listSelector {
  display: flex;
  flex-direction: row;
  height: 100%;

  &-list {
    flex: auto 1 1;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
      inset 0 0 0 1px rgba(16, 22, 26, 0.15),
      inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background: #ffffff;

    p {
      font-weight: 600;
    }

    ul {
      padding: 5px;
      flex: auto 1 1;
      overflow-y: auto;

      li {
        justify-content: left;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: #eee;
        }

        > * {
          margin-right: 2px;
        }
      }
    }

    .focus-listSelector-selectAll {
      padding: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ccc;
    }
  }
}
