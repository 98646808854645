@import "../../variables.scss";

.focus-archive-tree {
  &_row {
    display: flex;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin-top: -1px;

    &_selected {
      background-color: $blue2;
      color: $white;
      font-weight: bolder;
    }
  }

  &_clickable-node {
    &:hover {
      background-color: $light-gray1;
      color: $dark-gray2;
      cursor: pointer;
    }
  }
}

.focus-smaller-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 20px;
  & > * {
    width: 100%;
  }
}
