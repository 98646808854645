.cdm-table-collapsible {
    margin-top: 10px;
    position: absolute;
    left: 0;
    z-index: 1 !important;
    width: 100%;
}

.ndm-table-tabs-container {
    background-color: whitesmoke;
    padding: 10px
}