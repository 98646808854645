.inlined-label {
  display: flex;
  align-items: center;
  align-content: flex-end;
  flex-direction: row-reverse;
  width: 300px;

  > label.fw-flexible-label {
    display: flex;
    flex: 1 1 auto;
    margin: 3px 0;
  }
}

.inlined-middle > label.fw-flexible-label {
  display: flex;
  flex: 1 1 auto;
  margin: 3px 0;

  > .formLabel > span {
    margin-top: 0;
  }
}

.limited-tooltip {
  max-width: 500px;
}

.limited-tooltip > div:nth-child(2) {
  max-height: 200px;
  overflow-y: auto;
}

.inlined-middle {
  display: flex;
  align-items: center;
}
