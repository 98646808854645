// File table row related CSS
.filetablerow {
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
}

.filetablerow:hover {
  background-color : #f5f5f5;
  color: #182026;
}

.filetablerow-clicked {
  padding: 5px 10px;
  width: 100%;
  background-color: #333;
  color: #f5f5f5;
}

.filetablerow-cell-icon {
  display: inline-block;
  width: 5%;
}

.filetablerow-cell-name {
  display: inline-block;
  width: 55%;
  padding-right: 5px;
  word-wrap: break-word;
}

.filetablerow-cell-size {
  display: inline-block;
  width: 15%;
  word-wrap: break-word;
}

.filetablerow-cell-date {
  display: inline-block;
  width: 25%;
}