@import "../variables.scss";

.focus-actionableList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.focus-actionableList-selected {
    background: $pt-dark-icon-color-selected;
    color: $pt-dark-text-color;
  }
}

.removable-listmenu-item-select {
  padding: 3px 5px;
  cursor: pointer;
  border-radius: 3px;
  border-bottom: 1px solid rgb(227, 230, 233);
}

.removable-listmenu-item-select:last-of-type {
  border-bottom: none;
}

.removable-listmenu-item-select:hover {
  background-color: rgb(227, 230, 233);
}

.removable-listmenu-item {
  padding: 3px 5px;
  border-radius: 3px;
  border-bottom: 1px solid rgb(227, 230, 233);
}

.removable-listmenu-item:last-of-type {
  border-bottom: none;
}

.removable-listmenu-item-select input,
.removable-listmenu-item input {
  border-radius: 3px;
  border: solid 1px black;
  padding: 2px;
}

.delete-icon:hover {
  color: rgb(141, 0, 0);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
}

.edit-icon {
  margin-right: 5px;
}

.edit-icon:hover {
  color: rgb(141, 68, 0);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
}
