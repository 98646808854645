.listtable-div {
  margin-top: 5px;
  .ag-header {
    background-color: #f5f8fa;
  }
}

.listtable-div-disabled {
  background-color: #F6F6F6 !important;
  color: black;
}

.listtable-filter {
  float: right;
  margin-top: -35px !important;
}