.panel-header {
  display: flex;
  justify-content: space-between;
  margin: 15px;
  line-height: 30px;

  &__left-content,
  &__right-content {
    display: inline-block;
    font-size: 20px;
  }

  label {
    margin-right: 2rem;
    font-weight: 600;
    font-size: 30px;
  }
}
