.grey {
  background-color: #333;
}

.logo {
  height: 100%;
}

.title {
  font-weight: 600;
  line-height: 40px;
  font-size: 20px;
  font-style: italic;
  margin-bottom: 6px;
}

.header-flex {
  flex: 1;
  flex-direction: column;
  display: flex;
}
