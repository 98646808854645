.fw-card-program {
  text-align: left;
  min-width: 300px;
  flex: 1;
  margin: 5px;
  border-radius: 4px !important;
  padding:0px !important;
  & a {
    text-decoration: none !important;
  }
  &.with-bg {
    background: linear-gradient(
      50deg,
      rgb(244, 244, 244) 30%,
      rgb(248, 248, 248) 10%,
      white,
      white
    );
  }

}
.fw-card_content {
  padding :20px
}

.fw-card-program p {
  width: 250px;
  color: rgb(85, 85, 85);
}
.fw-card-program h3 a {
  width: 250px;
  color: rgb(61, 60, 60);
}
