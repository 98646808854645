@import "@focus/focusUI/lib/variables";

.focus-actionableList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.focus-actionableList-selected {
    background: $pt-dark-icon-color-selected;
    color: $pt-dark-text-color;
  }
}

.focus-listbutton-disabled {
  cursor: not-allowed;
}
