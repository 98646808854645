.tab-option-inline {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.tab-container {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
}

.option-item {
    padding-right: 10px;
}

.cron-picker-actions {
    display: flex;
    justify-content: flex-end;
}