.focus-selectable-point-label {
  font-family: monospace;
  background: white;
  padding: 10px;
  margin: 5px;
  height: fit-content;
  min-width: max-content;
  border-radius: 2px;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  position: absolute;
  pointer-events: none;
  color: white;
  background-color:#404854;
  & > span {
    display: flex;
  }
  &.top-left::after {
    content: " ";
    position: absolute;
    left: 5px;
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid rgba(40, 42, 44, 0.85);
  }
  &.bottom-left::after {
    content: " ";
    position: absolute;
    left: 5px;
    bottom: -10px;
    border-top: 10px solid rgba(40, 42, 44, 0.85);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: none;
  }
  &.bottom-right::after {
    content: " ";
    position: absolute;
    right: 5px;
    bottom: -10px;
    border-top: 10px solid rgba(40, 42, 44, 0.85);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: none;
  }
  &.top-right::after {
    content: " ";
    position: absolute;
    right: 5px;
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid rgba(40, 42, 44, 0.85);
  }
}
