.cesium-widget-credits {
    display: none !important
}

.cesium-widgets {
    position: absolute;
    top: 16px;
    left: 30px;
    z-index: 1;

    label {
        color: white;
        text-align: center;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: sans-serif;
}

.camera-widget {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cesium-viewer-cesiumWidgetContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
}

.cesium-widget {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.cesium-button {
    height: 30px;
}

.show-events-widget {
    align-self: flex-start;
    width: 222px;
}