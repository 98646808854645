// File table related CSS
.filetable {
  margin-bottom: 20px;
}

.pathselector-field {
  vertical-align: bottom;
  display: inline-flex;
  height: 30px;
  width: 70%;
  font-weight: bold;
}

.filetable-bodycontainer {
  height: 500px;
  margin-bottom: 12px;
  padding: 3px 0px;
  border: 1px solid #bbb;
  border-radius: 5px;
  background: white;
}
.filetable-body {
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: auto;
  border-radius: 4px;
}

.header-orderer,
.header-orderer:hover {
  color: #182026;
}

.filetable-header {
  margin-top: 18px;
  padding: 5px 10px;
  width: 99%;
  font-weight: bold;
}
